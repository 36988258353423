import { h } from 'hyperapp'
import uuid from 'uuid/v4'
import equals from 'deep-equal'
import persist from 'hyperapp-persist-state'

import { wrapNested, valueSetter } from '../utils'

import {
  createInquiry,
  updateInquiry,
  checkContactVerification
} from '../services/graphcool'
import {
  stateToInquiry,
  Page,
  deriveMultiPageFormState,
  deriveVerificationState,
  deriveWidgetNavigationState
} from '../services/state'

import { ReCaptcha, executeReCaptcha } from './recaptcha'
import { DetailsPage, DetailsState, DetailsActions } from './detailspage'
import { InquiryPage, InquiryActions, InquiryState } from './inquirypage'
import { ContactState, ContactActions, ContactPage } from './contactpage'
import { ThankYouPage } from './thankyoupage'
import { VerificationPage } from './verificationpage'
import { MultipageForm } from './multipageform'
import {
  WidgetNavigationState,
  WidgetNavigationActions,
  WidgetNavigation
} from './widgetnavigation'

let state = {
  token: null,
  sessionToken: '',
  details: DetailsState,
  inquiry: InquiryState,
  contact: ContactState,
  sending: false,
  sent: null,
  id: null,
  nav: WidgetNavigationState(Page.INQUIRY),
  firstTimeContinue: false,
  verifyingContact: false,
  failedContactVerification: null
}

const resetablePages = ['inquiry', 'details', 'contact']

const actions = {
  details: DetailsActions,
  inquiry: InquiryActions,
  contact: ContactActions,
  nav: WidgetNavigationActions,
  setFirstTimeContinue: valueSetter('firstTimeContinue'),
  requestContactVerification: (state, actions) => update => {
    const toSend = stateToInquiry(state)
    if (state.sending || equals(state.sent, toSend)) return
    const commit = id =>
      update(state => {
        return {
          ...state,
          sent: toSend,
          sending: false,
          id
        }
      })
    const abort = () => {
      update(state => {
        return {
          ...state,
          sending: false
        }
      })
      actions.nav.goTo(Page.CONTACT)
    }
    update({ sending: true })
    executeReCaptcha(reCaptchaResult => {
      const request = {
        inquiry: toSend,
        reCaptchaResult,
        id: state.id,
        onsuccess: id => {
          commit(id)
        },
        onerror: err => {
          console.error(err)
          abort()
        }
      }
      state.id ? updateInquiry(request) : createInquiry(request)
    })
  },
  verifyContact: (state, actions, verificationCode) => update => {
    if (
      verificationCode.length !== 5 ||
      verificationCode === state.failedContactVerification ||
      state.verifyingContact
    )
      return
    const { id, sessionToken } = state
    update({
      ...state,
      verifyingContact: true
    })
    const commit = () => {
      resetablePages.forEach(key => {
        actions[key].reset()
      })
      setTimeout(
        () =>
          update(state => {
            if (!state.firstTimeContinue) actions.nav.goTo(Page.INQUIRY)
          }),
        5000
      )
      update(state => {
        return {
          ...state,
          verifyingContact: false,
          failedContactVerification: null,
          id: null,
          sent: null,
          sending: false,
          firstTimeContinue: false,
          nav: WidgetNavigationState(Page.THANKYOU)
        }
      })
    }
    const abort = verificationCode => {
      update(state => {
        return {
          ...state,
          verifyingContact: false,
          failedContactVerification: verificationCode
        }
      })
    }
    checkContactVerification({
      id,
      verificationCode,
      sessionToken,
      onsuccess: valid => {
        if (valid) {
          commit()
        } else {
          abort(verificationCode)
        }
      },
      onerror: err => {
        console.error(err)
        abort(null)
      }
    })
  },
  setSessionTokenIfMissing: (state, actions, newToken) => {
    if (!state.sessionToken) actions.setSessionToken(newToken)
  },
  setSessionToken: valueSetter('sessionToken'),
  setToken: valueSetter('token'),
  setBoundingRect: (state, __, rect) => {
    return { ...state, width: rect.width, height: rect.height }
  }
}

const getQueryVariable = variable => {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1])
    }
  }
}

const events = {
  action: (state, __, { name, data }) => {
    console.group(`Action ${name}`)
    console.log(data)
    console.log(state)
    console.groupEnd()
  },
  load: (state, actions, element) => {
    if (FINDECK_WIDGET_PERSISTING) actions.__initPersist()
    actions.setToken(getQueryVariable('token'))
    actions.setSessionTokenIfMissing(uuid())
    const setBounds = () =>
      actions.setBoundingRect(element.parentNode.getBoundingClientRect())
    window.onresize = setBounds
    setBounds()
  }
}

const defaultView = (state, actions) => (
  <div>
    <div class="findeck">
      <MultipageForm
        state={deriveMultiPageFormState(state)}
        actions={actions.multipageform}
      >
        <InquiryPage state={state.inquiry} actions={actions.inquiry} />
        <DetailsPage state={state.details} actions={actions.details} />
        <ContactPage state={state.contact} actions={actions.contact} />
        <VerificationPage state={deriveVerificationState(state)} />
        <ThankYouPage />
      </MultipageForm>
      <WidgetNavigation
        state={deriveWidgetNavigationState(state)}
        actions={actions.nav}
        leaveActions={{
          [Page.INQUIRY]: () => actions.setFirstTimeContinue(true)
        }}
        enterActions={{
          [Page.CONFIRMATION]: () => actions.requestContactVerification()
        }}
        onInputChange={actions.verifyContact}
      />
    </div>
    <ReCaptcha
      onsolve={actions.captchaSuccess}
      sitekey="6Ld9PzEUAAAAADp38EJXd4Zv8WUfF2xAGrJ60-yP"
    />
  </div>
)

const widgetOpts = {
  state,
  view: defaultView,
  actions: wrapNested(actions),
  events
}

const Widget = FINDECK_WIDGET_PERSISTING
  ? persist(widgetOpts, {
      storage: 'localStorage',
      clearPast: true,
      version: 1
    })
  : widgetOpts

export { Widget }
