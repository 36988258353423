import { h } from 'hyperapp'

const recaptchaNS = 'recaptchaNSmedvogpobunkisinetevsoev'
const recaptchaOnload = 'recaptchaOnloadmedvogpobunkisinetevsoev'
const executeReCaptcha = onsolve => {
  if (window[recaptchaNS].solved) {
    grecaptcha.reset(window[recaptchaNS].id)
    window[recaptchaNS].solved = false
  }
  window[recaptchaNS].resolve = token => {
    console.log(token)
    window[recaptchaNS].solved = true
    return onsolve(token)
  }
  grecaptcha.execute(window[recaptchaNS].id)
}

const ReCaptcha = ({ sitekey }) => {
  if (!window[recaptchaNS]) {
    window[recaptchaOnload] = function() {
      const recaptcha = window[recaptchaNS]
      recaptcha.update = function() {
        const { sitekey, element } = this.conf
        this.id = grecaptcha.render(element, {
          sitekey,
          callback: token => {
            console.log(token)
            window[recaptchaNS].resolve(token)
          },
          size: 'invisible'
        })
      }
      if (recaptcha.conf) {
        recaptcha.update()
      }
    }
    window[recaptchaNS] = {
      update: () => {},
      solved: false
    }
    const st = document.createElement('script')
    st.setAttribute(
      'src',
      `https://www.google.com/recaptcha/api.js?onload=${recaptchaOnload}&render=explicit`
    )
    st.setAttribute('type', `text/javascript`)
    st.setAttribute('async', 'async')
    st.setAttribute('defer', 'defer')
    document.head.appendChild(st)
  }
  return (
    <div
      oncreate={elm => {
        const recaptcha = window[recaptchaNS]
        recaptcha.conf = { sitekey, element: elm }
        recaptcha.update()
      }}
    />
  )
}

export { ReCaptcha, executeReCaptcha }
