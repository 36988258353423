import { h } from 'hyperapp'

import { eventSetter } from '../utils'

const ContactState = {
  type: 'SMS',
  handle: null
}

const ContactActions = {
  setHandle: eventSetter('handle'),
  reset: () => {
    return ContactState
  }
}

const ContactValidator = ({ handle }) => handle && handle !== ''

const ContactPage = ({ state, actions, onvalidation }) => {
  return (
    <div>
      <p class="large">
        Wir informieren Dich per SMS.<br /> An welche Nummer?
      </p>
      <div class="row">
        <label>+49 (0)</label>
        <input
          tabindex="-1"
          type="text"
          value={state.handle}
          oninput={actions.setHandle}
        />
      </div>
    </div>
  )
}

export { ContactState, ContactActions, ContactPage, ContactValidator }
