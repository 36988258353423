import { h } from 'hyperapp'
import back from '../icons/ios-arrow-back.svg'
import calendar from '../icons/md-calendar.svg'
import chatboxes from '../icons/md-chatboxes.svg'
import checkmark from '../icons/md-checkmark-circle.svg'
import clipboard from '../icons/md-clipboard.svg'
import euro from '../icons/logo-euro.svg'
import forward from '../icons/ios-arrow-forward.svg'
import image from '../icons/md-image.svg'
import logo from '../icons/icon-app-masked-72.svg'

const icons = {
  back,
  calendar,
  chatboxes,
  checkmark,
  clipboard,
  euro,
  forward,
  image,
  logo
}

// See https://github.com/hyperapp/hyperapp/pull/211 for example.
const Use = ({ href }) => (
  <use
    oncreate={e =>
      e.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '#' + href)
    }
  />
)

const Icon = ({ id, color }) => (
  <svg viewBox={icons[id].viewBox} class="icon" fill={color}>
    <Use href={icons[id].id} />
  </svg>
)

export default Icon
