import {
  wnLabeledButton,
  wnNoButton,
  wnCurSelection,
  wnCurInput,
  wnNoInput,
  wnState
} from '../components/widgetnavigation'
import { InquiryValidator } from '../components/inquirypage'
import { DetailsValidator } from '../components/detailspage'
import { ContactValidator } from '../components/contactpage'
import { ImageSelectorRetrieveDataUrl } from '../components/dropzone'
import { printJSON } from '../utils'

export const Page = {
  INQUIRY: 'inquiry',
  DETAILS: 'details',
  CONTACT: 'contact',
  CONFIRMATION: 'confirmation',
  THANKYOU: 'thankyou'
}

const buttonTo = (page, state, label = '') => {
  const valid = {
    inquiry: InquiryValidator(state.inquiry),
    details: DetailsValidator(state.details),
    contact: ContactValidator(state.contact)
  }
  switch (page) {
    case Page.INQUIRY:
      return wnLabeledButton(
        label,
        wnCurSelection(state.nav) !== Page.INQUIRY,
        page
      )
    case Page.DETAILS:
      return wnLabeledButton(label, valid.inquiry, page)
    case Page.CONTACT:
      return wnLabeledButton(label, valid.inquiry && valid.details, page)
    case Page.CONFIRMATION:
      return wnLabeledButton(
        label,
        valid.inquiry &&
          valid.details &&
          valid.contact &&
          (wnCurSelection(state.nav) !== Page.CONFIRMATION || !state.sending),
        page
      )
    default:
      throw Error(`Button for unknown page ${page} requested`)
  }
}

const backButton = state => {
  switch (wnCurSelection(state.nav)) {
    case Page.INQUIRY:
      return state.firstTimeContinue
        ? buttonTo(Page.INQUIRY, state)
        : wnNoButton()
    case Page.DETAILS:
      return buttonTo(Page.INQUIRY, state)
    case Page.CONTACT:
      return buttonTo(Page.DETAILS, state)
    case Page.CONFIRMATION:
      return buttonTo(Page.CONTACT, state)
    case Page.THANKYOU:
      return wnNoButton()
    default:
      throw new Error(
        `Invalid state (selected page unknown): ${printJSON(state)}`
      )
  }
}

const continueButton = state => {
  switch (wnCurSelection(state.nav)) {
    case Page.INQUIRY:
      return buttonTo(Page.DETAILS, state, 'Finden')
    case Page.DETAILS:
      return buttonTo(Page.CONTACT, state, 'Kontakt')
    case Page.CONTACT:
      return buttonTo(Page.CONFIRMATION, state, 'Absenden')
    case Page.CONFIRMATION:
      return wnNoButton()
    case Page.THANKYOU:
      return buttonTo(Page.INQUIRY, state, 'Neu')
  }
}

const dotButtons = state => {
  const dots = [
    buttonTo(Page.INQUIRY, state),
    buttonTo(Page.DETAILS, state),
    buttonTo(Page.CONTACT, state)
  ]
  switch (wnCurSelection(state.nav)) {
    case Page.INQUIRY:
      return state.firstTimeContinue ? dots : []
    case Page.DETAILS: // falltrhough
    case Page.CONTACT:
      return dots
    case Page.CONFIRMATION: // falltrhough
    case Page.THANKYOU:
      return []
  }
}

const showLegal = state =>
  state.width > 370 ||
  (wnCurSelection(state.nav) === Page.INQUIRY && !state.firstTimeContinue)

const cofirmationInput = state =>
  wnCurSelection(state.nav) === Page.CONFIRMATION
    ? wnCurInput(state.nav)
    : wnNoInput()

export const deriveWidgetNavigationState = state =>
  wnState(
    wnCurSelection(state.nav),
    backButton(state),
    continueButton(state),
    dotButtons(state),
    cofirmationInput(state),
    showLegal(state)
  )

export const deriveMultiPageFormState = state =>
  [
    Page.INQUIRY,
    Page.DETAILS,
    Page.CONTACT,
    Page.CONFIRMATION,
    Page.THANKYOU
  ].findIndex(p => p === wnCurSelection(state.nav))

export const deriveVerificationState = state =>
  state.sending || !state.sent
    ? null
    : {
        inquiry: {
          text: state.sent.text,
          until: {
            date: state.sent.untilDate,
            month: state.sent.untilMonth,
            year: state.sent.untilYear
          },
          priceRange: state.sent.pricerangeMin
            ? {
                max: state.sent.pricerangeMax,
                min: state.sent.pricerangeMin
              }
            : {
                max: state.sent.pricerangeMax
              },
          contact: `+49 (0) ${state.sent.contactHandle}`,
          image:
            state.inquiry.image && state.inquiry.image.id === state.sent.imageId
              ? ImageSelectorRetrieveDataUrl(state.inquiry.image.dataUrl)
              : null
        },
        failed:
          !state.verifyingContact && state.failedContactVerification !== null,
        verifying: state.verifyingContact,
        image: null
      }

export const stateToInquiry = state => {
  const { inquiry, details, contact, token, sessionToken } = state
  const { text, image } = inquiry
  if (text === '') {
    return null
  }
  const {
    until,
    pricerange: { min = null, max }
  } = details
  if (until === null) {
    return null
  }
  const { year, month, date } = until
  const { type, handle } = contact
  if (handle === null) {
    return null
  }
  const imageId = image && image.id ? image.id : null
  return {
    widgetTokenId: token,
    sessionToken,
    text,
    imageId,
    untilDate: date,
    untilMonth: month,
    untilYear: year,
    pricerangeMin: min,
    pricerangeMax: max,
    contactType: type,
    contactHandle: handle
  }
}
