import { h } from 'hyperapp'
import Icon from './icon'

import Dropzone from 'dropzone'
import uuid from 'uuid/v4'

let dataUrls = {}
let files = {}

const ImageSelector = ({ url, state, actions }, children) => {
  url = 'https://api.graph.cool/file/v1/findeck-staging'
  return (
    <div
      class="dropzone"
      oncreate={elm => {
        const dz = new Dropzone(elm, {
          url,
          clickable: '.image-selector-select-button',
          previewsContainer: '.image-selector-preview-area',
          acceptedFiles: 'image/*',
          addRemoveLinks: true,
          paramName: 'data',
          maxFiles: 1,
          dictRemoveFile: '╳',
          dictCancelUpload: '╳'
        })
        dz.on('addedfile', file => {
          //   console.log("addedFile");
          dz.clickableElements.forEach(e => (e.style = 'display: none;'))
          actions.addImage(file)
        })
        dz.on('maxfilesexceeded', file => {
          dz.files.forEach(file => dz.removeFile(file))
          dz.addFile(file)
        })
        dz.on('processing', file => {
          console.log('startedProcessing')
        })
        dz.on('uploadprogress', (file, progress) => {
          actions.updateProgress({ file, progress })
          //   console.log(`progress:${progress}`);
        })
        dz.on('success', (file, response) => {
          console.log(response)
          actions.setId({ file, id: JSON.parse(response).id })
        })
        dz.on('error', (file, error) => {
          //   console.log(error);
          actions.removeImage(file)
        })
        dz.on('cancled', file => {
          //   console.log("cancled");
          actions.removeImage(file)
        })
        dz.on('removedfile', file => {
          //   console.log("removed");
          dz.clickableElements.forEach(e => (e.style = ''))
          actions.removeImage(file)
        })
        dz.on('thumbnail', (file, thumbnail) => {
          actions.addPreview({ file, thumbnail })
          // console.log(`thumbnail: ${thumbnail}`)
        })
      }}
      onupdate={elm => {
        if (state === null) {
          const dz = elm.dropzone
          Object.keys(files).forEach(id => dz.removeFile(files[id]))
        }
      }}
      onremove={elm => {
        throw new Error('not Implemented')
      }}
    >
      {children}
    </div>
  )
}

const ImageSelectorSelectButton = () => {
  return (
    <button tabindex="-1" class="image-selector-select-button">
      <Icon id="image" />
    </button>
  )
}

const ImageSelectorPreviewArea = () => {
  return <div class="image-selector-preview-area" />
}

const ImageSelectorRetrieveDataUrl = id => {
  return dataUrls[id]
}

const ImageSelectorState = null

const ImageSelectorActions = {
  addPreview: (state, _, { file, thumbnail }) => {
    if (state && files[state.file] === file) {
      const id = uuid()
      dataUrls[id] = thumbnail
      return { ...state, dataUrl: id }
    }
  },
  addImage: (state, __, file) => {
    if (state) {
      delete files[state.file]
      if (state.dataUrl) {
        delete dataUrls[state.dataUrl]
      }
    }
    const id = uuid()
    files[id] = file
    return { file: id }
  },
  removeImage: (state, _, file) => {
    if (state && files[state.file] === file) {
      delete files[state.file]
      if (state.dataUrl) {
        delete dataUrls[state.dataUrl]
      }
      return null
    }
  },
  updateProgress: (state, _, { file, progress }) => {
    if (state && files[state.file] === file) {
      return { ...state, progress: progress / 100 }
    }
  },
  setId: (state, _, { file, id }) => {
    if (state && files[state.file] === file) {
      return { ...state, id }
    }
  }
}

export {
  ImageSelector,
  ImageSelectorState,
  ImageSelectorActions,
  ImageSelectorSelectButton,
  ImageSelectorPreviewArea,
  ImageSelectorRetrieveDataUrl
}
