const graphCoolFileUpload = {
  url: GRAPH_COOL_FILE_URL,
  method: 'POST'
}

const graphCoolMutation = {
  url: GRAPH_COOL_SIMPLE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  method: 'POST'
}

const peToProgress = ({ total, loaded }) =>
  Math.min(Math.max(0, loaded / total), 1)
const seToFileID = ({ target: { responseText } }) => JSON.parse(responseText).id

const fileUpload = ({ file, onprogress, oncomplete, onerror }) => {
  const { url, method } = graphCoolFileUpload
  const fd = new FormData()
  fd.append('data', file)
  const xhr = new XMLHttpRequest()
  xhr.open(method, url)
  xhr.upload.onprogress = pe => onprogress(peToProgress(pe))
  xhr.onerror = ee => {
    console.log(ee)
  }
  xhr.onload = se => oncomplete(seToFileID(se))
  xhr.send(fd)
  return () => {
    xhr.abort()
  }
}

const inquiryToGraphQLParameter = ({
  widgetTokenId,
  sessionToken,
  text,
  imageId,
  untilDate,
  untilMonth,
  untilYear,
  pricerangeMin,
  pricerangeMax,
  reCaptchaResult,
  contactType,
  contactHandle
}) => {
  return {
    widgetTokenId,
    sessionToken,
    text,
    imageFileId: imageId,
    untilDate,
    untilMonth,
    untilYear,
    pricerangeMin,
    pricerangeMax,
    contactType,
    contactHandle,
    reCaptchaResult
  }
}

const inquiryToCreateMutation = `mutation (
  $widgetTokenId : ID!,
  $sessionToken : String!,
  $text : String!,
  $imageId : ID,
  $untilDate : Int!,
  $untilMonth : Int!,
  $untilYear : Int!,
  $pricerangeMin : Int,
  $pricerangeMax : Int!,
  $contactType : ChannelType!,
  $contactHandle : String!,
  $reCaptchaResult : String!
) {
  createWidgetInquiryQueue (
    widgetTokenId : $widgetTokenId,
    sessionToken : $sessionToken,
    text : $text,
    imageFileId : $imageId,
    untilDate : $untilDate,
    untilMonth : $untilMonth,
    untilYear : $untilYear,
    pricerangeMin : $pricerangeMin,
    pricerangeMax : $pricerangeMax,
    contactType : $contactType,
    contactHandle : $contactHandle,
    reCaptchaResult : $reCaptchaResult
  ) {
      id
      reCaptchaValid
  }
}`

const inquiryToUpdateMutation = `mutation (
  $id : ID!
  $widgetTokenId : ID!,
  $sessionToken : String!,
  $text : String!,
  $imageId : ID,
  $untilDate : Int!,
  $untilMonth : Int!,
  $untilYear : Int!,
  $pricerangeMin : Int,
  $pricerangeMax : Int!,
  $contactType : ChannelType!,
  $contactHandle : String!,
  $reCaptchaResult : String!
) {
  updateWidgetInquiryQueue (
    id: $id,
    widgetTokenId : $widgetTokenId,
    sessionToken : $sessionToken,
    text : $text,
    imageFileId : $imageId,
    untilDate : $untilDate,
    untilMonth : $untilMonth,
    untilYear : $untilYear,
    pricerangeMin : $pricerangeMin,
    pricerangeMax : $pricerangeMax,
    contactType : $contactType,
    contactHandle : $contactHandle,
    reCaptchaResult : $reCaptchaResult
  ) {
      id
      reCaptchaValid
  }
}`

const confirmContactMutation = `mutation (
  $id : ID!,
  $verificationCode : String!,
  $sessionToken : String!
) {
    updateWidgetInquiryQueue (
        id: $id,
        contactConfirmationCodeRecieved: $verificationCode,
        sessionToken: $sessionToken
    ) {
        id
        contactConfirmed
    }
}`

const sendMutation = (
  mutation,
  variables,
  { url, method, headers },
  onsuccess,
  onerror
) => {
  const xhr = new XMLHttpRequest()
  xhr.open(method, url)
  Object.keys(headers).forEach(key => xhr.setRequestHeader(key, headers[key]))
  console.log(mutation, variables)
  xhr.onerror = ee => {
    onerror(ee)
  }
  xhr.onload = se => {
    console.log(se)
    const res = JSON.parse(se.target.responseText)
    console.log(res)
    switch (true) {
      case Array.isArray(res.errors):
        onerror(new Error(JSON.stringify(res.errors)))
        break
      case res.error:
        onerror(new Error(JSON.stringify(res.error)))
        break
      default:
        onsuccess(res.data)
        break
    }
  }
  const body = JSON.stringify({
    query: mutation,
    variables
  })
  xhr.send(body)
}

const createInquiry = ({ inquiry, reCaptchaResult, onsuccess, onerror }) => {
  sendMutation(
    inquiryToCreateMutation,
    { ...inquiry, reCaptchaResult },
    graphCoolMutation,
    res => {
      if (!res.createWidgetInquiryQueue.reCaptchaValid) {
        onerror(new Error('invalid captcha'))
      }
      onsuccess(res.createWidgetInquiryQueue.id)
    },
    onerror
  )
}

const updateInquiry = ({
  inquiry,
  id,
  reCaptchaResult,
  onsuccess,
  onerror
}) => {
  sendMutation(
    inquiryToUpdateMutation,
    { ...inquiry, id, reCaptchaResult },
    graphCoolMutation,
    res => {
      if (!res.updateWidgetInquiryQueue.reCaptchaValid) {
        onerror(new Error('invalid captcha'))
      }
      onsuccess(res.updateWidgetInquiryQueue.id)
    },
    onerror
  )
}

const checkContactVerification = ({
  id,
  verificationCode,
  sessionToken,
  onsuccess,
  onerror
}) => {
  sendMutation(
    confirmContactMutation,
    { id, verificationCode, sessionToken },
    graphCoolMutation,
    res => {
      onsuccess(res.updateWidgetInquiryQueue.contactConfirmed)
    },
    onerror
  )
}

export { fileUpload, createInquiry, updateInquiry, checkContactVerification }
