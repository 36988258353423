import { h } from 'hyperapp'
import Icon from './icon'

const ThankYouPage = () => (
  <div class="row thankyoupage">
    <Icon id="checkmark" color="#8FE723" />
    <div class="column">
      <p>
        <strong>Alle Läden wurden informiert.</strong>
      </p>
      <p>Die Antworten kommen per SMS.</p>
    </div>
  </div>
)

export { ThankYouPage }
