import { h } from 'hyperapp'
import NoUiSlider from 'nouislider'
import equals from 'deep-equal'

const priceRangeToArray = ({ min, max }) => [min ? min : 0, max]
const arrayToPriceRange = arr => {
  const rounded = arr.map(Math.round.bind(Math))
  const [min, max] = rounded
  return min == 0 ? { max } : { min, max }
}

const arrEqual = (a, b) =>
  a.reduce((accum, val, ind) => accum && val === b[ind], true)

const PriceRangeSelector = ({ pricerange, onchange }) => {
  return (
    <div>
      <label for="pricerangeselector">
        Wieviel möchtest Du dafür ausgeben?
      </label>
      <div
        id="pricerangeselector"
        oncreate={elm => {
          const tooltipFormatter = {
            to: num => Math.round(num) + ',- €'
          }
          NoUiSlider.create(elm, {
            start: priceRangeToArray(pricerange),
            range: {
              min: [0, 1],
              '25%': [10, 2],
              '50%': [100, 5],
              '75%': [1000, 50],
              max: [10000]
            },
            connect: true,
            pips: {
              mode: 'range',
              density: 3
            },
            tooltips: [tooltipFormatter, tooltipFormatter]
          })
          elm.noUiSlider.on('slide', values => {
            onchange(arrayToPriceRange(values))
          })
          const handle = elm.querySelectorAll('.noUi-handle')
          // Workaround: IE 11 does not support querySelectorAll.forEach
          Array.prototype.forEach.call(handle, item =>
            item.setAttribute('tabindex', -1)
          )
        }}
        onupdate={elm => {
          if (!equals(arrayToPriceRange(elm.noUiSlider.get()), pricerange)) {
            elm.noUiSlider.off('slide')
            elm.noUiSlider.set(priceRangeToArray(pricerange))
            elm.noUiSlider.on('slide', values => {
              onchange(arrayToPriceRange(values))
            })
          }
        }}
        onremove={elm => {
          // undocumented, see https://github.com/leongersen/noUiSlider/issues/315
          elm.noUiSlider.destroy()
        }}
      />
    </div>
  )
}

export default PriceRangeSelector
