const loadDataUrl = ({ file, onload }) => {
  const reader = new FileReader()
  reader.onload = evt => onload(evt.target.result)
  reader.readAsDataURL(file)
  return () => {
    reader.abort()
  }
}

export { loadDataUrl }
