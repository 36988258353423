import { h } from 'hyperapp'

import { valueSetter, eventSetter } from '../utils'

import { fileUpload } from '../services/graphcool'
import { loadDataUrl } from '../services/fs'

import {
  ImageSelector,
  ImageSelectorState,
  ImageSelectorActions,
  ImageSelectorPreviewArea,
  ImageSelectorSelectButton
} from './dropzone'
import ImagePreview from './imagepreview'

const InquiryState = {
  text: '',
  maxLength: 1250,
  image: ImageSelectorState,
  placeholderIdx: 0
}

const InquiryActions = {
  image: ImageSelectorActions,
  setText: eventSetter('text'),
  fileDropped: (_, actions, e) => {
    const file = e.dataTransfer.files[0]
    if (/image\/./.test(file.type)) {
      actions.image.upload(file)
    } else {
      console.log('Incompatible Type')
    }
  },
  reset: () => {
    return InquiryState
  },
  changeSample: state => {
    return { ...state, placeholderIdx: (state.placeholderIdx + 1) % 2 }
  }
}

const InquiryValidator = ({ text }) => text && text !== ''

const placeholders = [
  'Beschreibe hier, was Du kaufen möchtest...',
  'Bsp: Hallo, ich suche eine Damen-Businesshose in Gr. 36, gerade geschnitten für Frühjahr/Sommer. Wer hat sowas?'
]

const InquiryPage = ({ state, actions }) => {
  return (
    <ImageSelector state={state.image} actions={actions.image}>
      <div class="row">
        <textarea
          tabindex="-1"
          oninput={actions.setText}
          placeholder={placeholders[state.placeholderIdx]}
          value={state.text}
        />
        <ImageSelectorPreviewArea />
        <ImageSelectorSelectButton />
      </div>
      <div
        class="row instruction"
        oncreate={elm => {
          elm.intervall = setInterval(actions.changeSample.bind(actions), 3000)
        }}
        onremove={elm => {
          clearInterval(elm.intervall)
          elm.parentNode.removeChild(elm)
        }}
      >
        <p>Sei nett und ausführlich, wir sind Menschen :)</p>
      </div>
    </ImageSelector>
  )
}

export { InquiryPage, InquiryActions, InquiryState, InquiryValidator }
