import { h } from 'hyperapp'

import { loadingGif } from '../utils'

const ImagePreview = ({
  image: { dataUrl = loadingGif, progress = 0, id = undefined },
  ondelete = undefined
}) => {
  return (
    <div>
      <img src={dataUrl} />
      <progress value={Math.round(progress * 100)} max="100" />
      <p>{id ? 'ok' : 'uploading... ' + Math.round(progress * 100) + '%'}</p>
      {typeof ondelete === 'function' ? (
        <button onclick={ondelete}>Delete</button>
      ) : (
        undefined
      )}
    </div>
  )
}
export default ImagePreview
