import { h } from 'hyperapp'

import { valueSetter } from '../utils'

import DatePicker from './datepicker'
import PriceRangeSelector from './pricerangeselector'

const jsDateToDate = js => {
  return {
    year: js.getFullYear(),
    month: js.getMonth() + 1,
    date: js.getDate()
  }
}
const until = state => state.until
const pricerange = state => state.pricerange
const DetailsPage = ({ state, actions }) => {
  return (
    <div>
      <PriceRangeSelector
        pricerange={pricerange(state)}
        onchange={actions.setPricerange}
      />
      <DatePicker
        date={until(state)}
        min={jsDateToDate(new Date())}
        onchange={actions.setUntil}
      />
    </div>
  )
}

const DetailsState = {
  pricerange: { max: 100 }, // {min: number, max: number} number in Euro
  until: null // {year: number, month: number, day: number} gregorian
}

const DetailsActions = {
  setPricerange: valueSetter('pricerange'),
  setUntil: valueSetter('until'),
  reset: () => {
    return DetailsState
  }
}

const DetailsValidator = ({ until }) => until !== null

export { DetailsPage, DetailsState, DetailsActions, DetailsValidator }
