import { h } from 'hyperapp'

const MultipageForm = ({ state, actions, style }, pages) => {
  const numPages = pages.length

  let page = state
  switch (true) {
    case page < 0:
      page = 0
      break
    case page >= numPages:
      page = numPages - 1
      break
  }
  const slides = pages.map((page, ind) => {
    return (
      <div
        key={`page${ind}`}
        style={{
          width: `${100 / pages.length}%`,
          overflow: 'hidden'
        }}
      >
        {page}
      </div>
    )
  })
  // const waitImg = <img src={loadingGif} style={{
  //     width: '10px'
  // }}/>

  return (
    <div style={Object.assign({}, style, { overflow: 'hidden' })}>
      <article
        class="container"
        style={{
          display: 'flex',
          width: `${pages.length}00%`,
          transform: `translateX(-${page * 100 / pages.length}%)`,
          transition: 'transform .3s'
        }}
      >
        {slides}
      </article>
    </div>
  )
}

export { MultipageForm }
