import { h } from 'hyperapp'
import Icon from './icon'

import { eventSetter, valueSetter } from '../utils'

import ImagePreview from './imagepreview'

const dateToString = ({ year, month, date }) => `${date}.${month}.${year}`

const VerificationPage = ({ state, actions }) => {
  console.log(state)
  return !state ? (
    <div class="loader" />
  ) : (
    <div
      class={
        state.inquiry.image ? 'verificationpage with-image' : 'verificationpage'
      }
    >
      {state.inquiry.image ? <img src={state.inquiry.image} /> : undefined}
      <div class="row text">
        <Icon id="clipboard" color="#DA216B" />
        <p>{state.inquiry.text}</p>
      </div>
      <div class="row">
        <div class="row">
          <Icon id="calendar" color="#DA216B" />
          <p>{dateToString(state.inquiry.until)}</p>
        </div>
        <div class="row">
          <Icon id="euro" color="#DA216B" />
          <p>
            {state.inquiry.priceRange.min ? (
              <span>
                von {state.inquiry.priceRange.min},-<br />
              </span>
            ) : (
              ''
            )}
            bis {state.inquiry.priceRange.max},-
          </p>
        </div>
      </div>
      <div class="row">
        <Icon id="chatboxes" color="#DA216B" />
        <p>{state.inquiry.contact}</p>
      </div>
      <div class="row instruction">
        <p>Stimmt alles? Bestätige mit dem Code in der SMS</p>
      </div>
    </div>
  )
}

export { VerificationPage }
