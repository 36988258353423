import { h } from 'hyperapp'
import Icon from './icon'

import { eventSetter, valueSetter, combineActions } from '../utils'

export const wnLabeledButton = (label, active, target) => ({
  ...wnButton(active, target),
  label
})
export const wnButton = (active, target) => ({ active, target })
export const wnNoButton = () => null
export const wnNoInput = () => null

export const wnCurSelection = state => state.selected
export const wnCurInput = state => state.input

export const wnState = (selected, back, cont, dots, input, legal) => ({
  selected,
  back,
  continue: cont,
  dots,
  input,
  legal
})

export const WidgetNavigationState = initialPage =>
  wnState(initialPage, wnNoButton(), wnNoButton(), [], '', true)

const limit5 = value => value.slice(0, 5)

export const WidgetNavigationActions = {
  goTo: valueSetter('selected'),
  inputChange: valueSetter('input', evt =>
    limit5(evt.target.value.toUpperCase())
  )
}

const mkGoTo = (actions, enterActions, leaveActions, current) => target => {
  if (current == target) return
  if (leaveActions[current]) leaveActions[current](target)
  actions.goTo(target)
  if (enterActions[target]) enterActions[target](current)
}

const mkInputChange = (actions, inputChange) =>
  inputChange
    ? combineActions(actions.inputChange, evt =>
        inputChange(limit5(evt.target.value.toUpperCase()))
      )
    : actions.inputChange

export const WidgetNavigation = ({
  state,
  actions,
  enterActions = {},
  leaveActions = {},
  onInputChange = null
}) => {
  const goTo = mkGoTo(actions, enterActions, leaveActions, state.selected)
  const inputChange = mkInputChange(actions, onInputChange)
  return (
    <nav>
      <div class="corporate row">
        <div class="logo">
          {FINDECK_WIDGET_ENV === 'production' ? null : (
            <div class="test">Test</div>
          )}
          <Icon id="logo" />
        </div>
        {state.legal ? (
          <div class="column">
            <p>Findeck</p>
            <div class="column legal">
              <a href="https://findeck.de/datenschutz.html">Datenschutz</a>
              <a href="https://findeck.de/impressum.html">Impressum</a>
            </div>
          </div>
        ) : null}
      </div>
      <div class="row">
        {state.back ? (
          <button
            class="back"
            onclick={() => goTo(state.back.target)}
            disabled={!state.back.active}
          >
            <Icon id="back" /> {state.back.label}
          </button>
        ) : null}
        {(state.dots || []).map(dot => (
          <span
            class={dot.target === state.selected ? 'is-active' : ''}
            onclick={dot.active ? () => goTo(dot.target) : () => {}}
          />
        ))}
        {state.continue ? (
          <button
            onclick={() => goTo(state.continue.target)}
            disabled={!state.continue.active}
          >
            {state.continue.label} <Icon id="forward" />
          </button>
        ) : null}
        {state.input !== null ? (
          <input
            oncreate={element => element.focus()}
            type="text"
            placeholder="_ _ _ _ _"
            oninput={inputChange}
            value={state.input}
          />
        ) : null}
      </div>
    </nav>
  )
}
