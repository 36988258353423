import { h } from 'hyperapp'

import FlatPickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de.js'

const dateToJs = ({ year, month, date }) => new Date(year, month - 1, date)
const jsToDate = date => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    date: date.getDate()
  }
}

const changeHandler = handler => dates =>
  handler(dates[0] ? jsToDate(dates[0]) : null)

const eqJsDate = (a, b) => a.getTime() === b.getTime()

const createFlatpickr = elm => {
  const fp = new FlatPickr(elm, {
    dateFormat: 'd.m.Y',
    disableMobile: 'true',
    locale: German,
    onChange: dates => elm._flatpickr._onchange(dates),
    minDate: 'today',
    maxDate: new Date().fp_incr(99)
  })
}
const setProps = elm => ({ date, onchange, min }) => {
  const fp = elm._flatpickr
  fp._onchange = changeHandler(onchange)
  fp.setDate(date ? [dateToJs(date)] : [], false)
  if (
    min &&
    (!fp.config.minDate || !eqJsDate(fp.config.minDate, dateToJs(min)))
  ) {
    fp.set('minDate', dateToJs(min))
  }
}

const DatePicker = props => {
  return (
    <div class="row">
      <label for="datepicker">Antworten bis zum:</label>
      <input
        tabindex="-1"
        id="datepicker"
        type="text"
        oncreate={elm => {
          createFlatpickr(elm)
          setProps(elm)(props)
        }}
        onupdate={elm => {
          setProps(elm)(props)
        }}
        onremove={elm => {
          elm._flatpickr.destroy()
          elm.parentNode.removeChild(elm)
        }}
      />
    </div>
  )
}

export default DatePicker
